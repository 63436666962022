<!-- http://192.168.1.6:8092/#/jump/noSignIn?uid=b32eb156a8d249de9d8ca05e323d8512 -->
<template>
	<div class="no-sign-in-container">
		<div class="loading-content">
			<img src="../../assets/login_images/logo.png" height="45" alt="" />
			<h3 class="title">临床研究项目及患者管理系统(CTMS)</h3>
			<p>正在进入系统，请稍候...</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "NoSignIn",
	data() {
		return {
			loading: false
		}
	},
	created() {
		this.handleAutoLogin()
	},
	methods: {
		handleAutoLogin() {
			this.$asyncDo(async () => {
				try {
					this.loading = true
					// 获取路由参数中的uid作为token
					const uid = this.$route.query.uid
					
					if (!uid) {
						this.$message.error('无效的访问链接，缺少必要参数')
						return
					}
					
					// 重置用户相关数据
					await this.$store.dispatch("user/loginDo",uid)
					 
					// 跳转到登录页面
					this.$router.push('/login')
				} catch (error) {
					console.error('自动登录失败:', error)
					this.$message.error('系统登录失败，请稍后再试')
				} finally {
					this.loading = false
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.no-sign-in-container {
	min-height: 100%;
	width: 100%;
	background-image: url("~@/assets/login_images/login-bg.jpg");
	background-size: cover;
	background-color: #394a6f;
	background-repeat: no-repeat;
	background-position: center;
	overflow: hidden;

	.loading-content {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		max-width: 100%;
		margin: 0 auto;
		overflow: hidden;
		background-color: #fff;
		border-radius: 8px;
		padding: 20px 40px 40px;
		width: 440px;
		text-align: center;
		
		.title {
			font-size: 22px;
			line-height: 40px;
			color: #333;
			margin: 20px auto;
			text-align: center;
			font-weight: bold;
		}
		
		p {
			font-size: 16px;
			color: #666;
			margin-top: 30px;
		}
	}
}
</style>
