import request from "@/utils/request"

// 注册
export const register = data =>
	request("/auth/register", {
		method: "post",
		body: data
	})
// 获取数据字典无token
export const getDicList = data =>
	request("/common/dictionaries/open-access", {
		method: "get",
		params: data
	})
// 获取用户角色
export const getRoles = data =>
	request("/auth/role/open", {
		method: "get",
		params: data
	})
// 获取审核详情
export const getRegDetail = data =>
	request("/auth/audit", {
		method: "get",
		params: data
	})
// 审核
export const audit = data =>
	request("/auth/audit", {
		method: "post",
		body: data
	})
// 上传文件
export const uploadFile = (data, params) =>
	request("/document/attachment/user-audit-file", {
		method: "post",
		body: data,
		params
	})
