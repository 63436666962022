<template>
	<!-- 新用户注册-->
	<div class="register-wrap">
		<Spin fix v-if="loading"></Spin>
		<section class="register-logo">
			<img src="../../assets/common/logo.png" />
		</section>
		<section class="register-body">
			<div class="register-main">
				<div class="register-main-title">新用户注册</div>
				<Form
					ref="xinhuaForm"
					:model="formData"
					:rules="ruleValidate"
					class="register-form"
					label-position="top"
				>
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="orgType" label="所属组织">
								<Select
									v-model="formData.orgType"
									placeholder="请选择组织"
									@on-change="orgTypeChange"
								>
									<Option
										:value="list.id"
										v-for="list in dicList"
										:key="list.id"
										:label="list.name"
									></Option>
								</Select>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="deptId" label="所属部门">
								<Select
									v-model="formData.deptId"
									filterable
									clearable
									placeholder="请选择所属部门"
								>
									<Option
										:value="list.id"
										v-for="list in deptLists"
										:key="list.id"
										:label="list.name"
									></Option>
								</Select>
							</FormItem>
						</Col>
					</Row>
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="username" label="用户名">
								<Input
									v-model="formData.username"
									placeholder="请输入医院单位邮箱地址"
								>
									<span slot="append">@xinhuamed.com.cn</span>
								</Input>
								<span
									style="
										position: absolute;
										top: -33px;
										left: 60px;
										font-size: 12px;
										color: red;
									"
								>
									（ 请使用医院单位邮箱 ）
								</span>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="realName" label="真实姓名">
								<Input
									v-model="formData.realName"
									readonly
									@on-focus="e => e.target.removeAttribute('readonly')"
									autocomplete="off"
									clearable
									placeholder="请输入真实姓名"
								/>
							</FormItem>
						</Col>
					</Row>
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="pass" label="密码">
								<Input
									readonly
									@on-focus="e => e.target.removeAttribute('readonly')"
									v-model.trim="formData.pass"
									@on-keyup.enter="handleSubmit"
									type="password"
									autocomplete="off"
									clearable
									placeholder="请输入密码"
								/>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="confirmPass" label="确认密码">
								<Input
									v-model.trim="formData.confirmPass"
									@on-keyup.enter="handleSubmit"
									type="password"
									autocomplete="off"
									clearable
									placeholder="请输入确认密码"
								/>
							</FormItem>
						</Col>
					</Row>
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="phone" label="手机号">
								<Input
									v-model="formData.phone"
									:maxlength="11"
									clearable
									placeholder="请输入手机号"
								/>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="sex" label="性别">
								<Select
									v-model="formData.sex"
									clearable
									:label-in-value="true"
									placeholder="请选择性别"
								>
									<Option
										:value="list.id"
										v-for="list in sexList"
										:key="list.id"
										:label="list.name"
									></Option>
								</Select>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="roles" label="用户角色">
								<Select
									clearable
									v-model="formData.roles"
									placeholder="请选择角色"
								>
									<Option
										:value="list.id"
										v-for="list in roleLists"
										:key="list.id"
										:label="list.name"
									></Option>
								</Select>
							</FormItem>
						</Col>
					</Row>
				</Form>
				<Row :gutter="60">
					<Col span="12" class="register-submit">
						<Button
							type="primary"
							:loading="loading"
							@click="handleSubmit"
							class="register-btn"
							>提交</Button
						>
					</Col>
					<Col span="12" class="register-cancel">
						<Button @click="handleCancel" class="register-btn">取消</Button>
					</Col>
				</Row>
			</div>
		</section>
	</div>
</template>
<script>
import publicApi from "@/api/public"
import { register, getDicList, getRoles } from "@/api/user/register"
import { dataToSha } from "@/utils/util"
import "./style.less"

const { publicGetDepartmentList } = publicApi

export default {
	components: {},
	data() {
		return {
			loading: false,
			formData: {
				orgType: "104"
			},
			ruleValidate: {
				orgType: {
					required: true,
					message: "组织不能为空",
					trigger: "change"
				},
				username: [
					{
						required: true,
						message: "请使用医院单位邮箱",
						trigger: "blur"
					},
					{ max: 30, message: "邮箱地址长度不能超过30", trigger: "blur" }
				],
				pass: [
					{ required: true, message: "请输入密码" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含一个大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePass, trigger: "blur" }
				],
				confirmPass: [
					{ required: true, message: "请输入确认密码" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含一个大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePassRepeat, trigger: "blur" }
				],
				realName: [
					{
						required: true,
						message: "真实姓名不能为空",
						trigger: "blur"
					},
					{ max: 20, message: "真实姓名长度不能超过20", trigger: "blur" }
				],
				deptName: {
					required: true,
					message: "部门名称不能为空",
					trigger: "blur"
				},
				deptId: {
					required: true,
					message: "部门不能为空",
					trigger: "change"
				},
				roles: {
					required: true,
					message: "用户角色不能为空",
					trigger: "change"
				},
				phone: [
					{
						required: true,
						message: "手机号不能为空",
						trigger: "blur"
					},
					{
						...this.rules.mobileNumber,
						message: "手机号码格式错误",
						trigger: "blur"
					}
				],
				sex: {
					required: true,
					message: "性别不能为空",
					trigger: "change"
				}
			},
			dicList: [], // 组织
			sexList: [], // 性别
			roleLists: [], // 角色
			deptLists: [] // 部门
		}
	},
	created() {
		this.initList()
		this.getXinhua()
	},
	methods: {
		validatePass(rule, value, callback) {
			// 验证密码
			if (value === "") {
				callback(new Error("请输入密码"))
			} else {
				if (this.formData.confirmPass !== "") {
					this.$refs.xinhuaForm.validateField("confirmPass")
				}
				callback()
			}
		},
		validatePassRepeat(rule, value, callback) {
			// 验证确认密码
			if (value === "") {
				callback(new Error("请再次输入密码"))
			} else if (value !== this.formData.pass) {
				callback(new Error("两次输入密码不一致!"))
			} else {
				callback()
			}
		},
		async initList() {
			this.loading = true
			const res = await getDicList({ isDel: 0 })
			if (res) {
				this.dicList = res.data
					.find(el => el.id === "1")
					.leaf.filter(o => ["104"].indexOf(o.id) !== -1)
				this.dicList = this.dicList.map(item => {
					if (item.id === "104") {
						item.name = "交通大学医学院附属医院"
					}
					return item
				})
				this.sexList = res.data.find(el => el.id === "6").leaf
			}
			this.loading = false
		},
		async getXinhua() {
			const res = await publicGetDepartmentList({
				// 获取医院下 部门，默认先传1.
				orgId: 1
			})
			if (res) {
				this.deptLists = res.data || []
			}
			const ret = await getRoles({
				// 获取角色
				pageSize: 1000,
				pageNum: 0,
				type: "104",
				isExternal: 1,
				isDel: 0
			})
			if (ret) {
				this.roleLists = ret.data.list || []
			}
		},
		async orgTypeChange(e) {
			if (e === "104") {
				const res = await publicGetDepartmentList({
					// 获取医院下 部门，默认先传1.
					orgId: 1
				})
				if (res) {
					this.deptLists = res.data || []
				}
			}
			const ret = await getRoles({
				// 获取角色
				pageSize: 1000,
				pageNum: 0,
				type: e,
				isExternal: 1,
				isDel: 0
			})
			if (ret) {
				this.roleLists = ret.data.list || []
			}
		},
		handleSubmit() {
			this.$refs.xinhuaForm.validate(valid => {
				if (valid) {
					const datas = {}
					Object.assign(datas, {
						...this.formData,
						pass: "",
						confirmPass: "",
						password: dataToSha(this.formData.confirmPass),
						roleIds: this.formData.roles,
						orgId: 1, // 代表医院
						orgName: "交通大学医学院附属医院",
						username: `${this.formData.username}@xinhuamed.com.cn`
					})
					this.$asyncDo(async () => {
						const res = await register({
							...datas
						})
						if (res) {
							this.$Message.success("注册成功")
							this.$router.push({ name: "login" })
						}
					})
				}
			})
			return true
		},
		handleCancel() {
			this.$router.push({ name: "login" })
		}
	}
}
</script>
